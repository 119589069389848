<template>
  <div class="bubble-map fill-height" />
</template>

<script>
import 'amcharts3';
import 'amcharts3/amcharts/plugins/responsive/responsive.js';
import 'amcharts3/amcharts/serial.js';
import 'amcharts3/amcharts/themes/light';

import 'ammap3';
import 'ammap3/ammap/maps/js/worldLow';

export default {
  name: 'bubbleMap',

  data () {
    return {
      map: null,
    };
  },

  props: {
    fillMap: {
      type: Boolean,
      default: false,
    },
    forReport: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    drawMap (mapData) {
      /* global AmCharts */
      const minBulletSize = 6;
      const maxBulletSize = 70;
      let min = Infinity;
      let max = -Infinity;
      AmCharts.theme = AmCharts.themes.light;

      const dataMap = mapData;

      // get min and max values
      dataMap.data.forEach((dataItem) => {
        const value = dataItem.value;
        if (value < min) {
          min = value;
        }
        if (value > max) {
          max = value;
        }
      });

      // build map
      this.map = new AmCharts.AmMap();
      // if (!this.map) {this.map = new AmCharts.AmMap()}

        this.map.zoomControl = {
          buttonFillColor: "#3b3b3b",
          buttonFillAlpha: 0.8,
          buttonColorHover: "#3b3b3b",
          buttonRollOverColor: "#3b3b3b",
          homeButtonEnabled: false,
          zoomControlEnabled: !this.forReport,
          top: 80,
          left: 16,
        };

      this.map.projection = 'winkel3';
      // this.map.addTitle('Population of the World in 2011', 14, 1, 1, false)
      // this.map.addTitle('source: Gapminder', 11, 1, 1, 1, false)
      this.map.areasSettings = {
        unlistedAreasColor: '#3b3b3b',
        unlistedAreasAlpha: 1,
        outlineColor: '#000000',
        outlineAlpha: 0.15,
        outlineThickness: 0.15,
      };

      this.map.imagesSettings = {
        balloonText: '<span style="font-size:14px"><b>[[title]]</b>: [[value]]</span>',
        alpha: 0.75,
      };

      const dataProvider = {
        mapVar: AmCharts.maps.worldLow,
        backgroundColor: "#303950",
        areas: [],
        images: [],
        areasSettings: {
          backgroundColor: "#303950",
        },
      };

      // create circle for each country
      // it's better to use circle square to show difference between values, not a radius
      const maxSquare = maxBulletSize * maxBulletSize * 2 * Math.PI;
      const minSquare = minBulletSize * minBulletSize * 2 * Math.PI;

      // create circle for each country
      dataMap.data.forEach(dataItem => {
        const value = dataItem.value;
        // calculate size of a bubble
        let square = (value - min) / (max - min) * (maxSquare - minSquare) + minSquare;
        if (square < minSquare) {
          square = minSquare;
        }
        let size = Math.sqrt(square / (Math.PI * 2));
        const id = dataItem.code;

        size = size >= 6 ? size : 6;

       let alpha = (((value * 100) / max) / 100) + 0.1;

        if (!dataMap.latlong[id]) {return false;}

        if(this.fillMap) {
        // <span style="font-size:14px"><b>[[dataItem.name]]</b>: [[value]]</span>
          dataProvider["areas"].push(
            {
              id: id,
              bringForwardOnHover: true,
              balloonText: `<span style="font-size:14px"><b>${dataItem.name}</b>: ${value}</span>`,
              title: dataItem.name,
              color: "#ff8726",
              rollOverColor: "#000000",
              alpha: alpha,
            },
          );
        }else {
          dataProvider.images.push({
            alpha: 0.55,
            type: 'circle',
            width: size,
            height: size,
            color: "#ff8726",
            longitude: dataMap.latlong[id].longitude,
            latitude: dataMap.latlong[id].latitude,
            title: dataItem.name,
            value: value,
          });
        }
      });

      this.map.dataProvider = dataProvider;
      this.map.write(this.$el);
    },
    destroy() {
      if(this.map) {
        this.map.destroy();
      }
    },
    clear() {
      if(this.map) {
        this.map.clear();
      }
    },
  },
};
</script>
